exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anti-slavery-policy-tsx": () => import("./../../../src/pages/anti-slavery-policy.tsx" /* webpackChunkName: "component---src-pages-anti-slavery-policy-tsx" */),
  "component---src-pages-gather-tsx": () => import("./../../../src/pages/gather.tsx" /* webpackChunkName: "component---src-pages-gather-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-studio-tsx": () => import("./../../../src/pages/studio.tsx" /* webpackChunkName: "component---src-pages-studio-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/projectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */)
}

